<colimo-project-header
        *ngIf="!!project"
        [project]="project"
        [lineId]="chartFilters.lineId"
        [modelId]="chartFilters.modelId"
        [kpis]="null"
        class="project-header margin-bottom-0">
</colimo-project-header>

<colimo-modal [size]="'md'"></colimo-modal>

<div class="multi-batch-page-wrapper flex-column gap-15">
    <mat-card appearance="outlined">
        <colimo-chart-filters
            [kundNr]="kundNr"
            [chartData]="chartData"
            [(chartFilters)]="chartFilters"
            [tolerancesOn]="tolerancesOn"
            [selectedAngles]="selectedAngles"
            [activeComponents]="activeComponents"
            [chartColors]="chartColors"
            [chartBorderColors]="chartBorderColors"
            [componentsLegend]="componentsLegend"
            [preselectedModelId]="preselectedModelId"
            [selectedTab]="selectedTab"
            [showExportButtons]="false"
            (selectedAnglesChange)="updateSelectedAngles($event)"
            (activeComponentsChange)="updateActiveComponents($event)"
            (modelsComponentsChange)="updateModelsComponents($event)"
            [componentTranslations]="componentTranslations"
        ></colimo-chart-filters>
    </mat-card>

    <mat-card appearance="outlined">
        <mat-tab-group mat-align-tabs="start" (selectedTabChange)="onTabSelected($event)" class="new-font-family">
            <mat-tab>
                <ng-template mat-tab-label>
                    <div class="flex-row gap-10">
                        <span>
                          {{ 'dashboard.mwg' | translate | tabIlluminant: chartData:selectedTab:0 }}
                        </span>
                        <mat-icon
                            *ngIf="tinterAdditionsCountTotal > 0"
                            class="tinter-addition-circle"
                            [matTooltip]="'dashboard.withTinterAdditions' | translate"
                            matTooltipPosition="above"
                            matTooltipClass="tinter-addition-tooltip"
                        >circle</mat-icon>
                    </div>
                </ng-template>
                <ng-template matTabContent>
                    <div class="flex-row flex-wrap">
                        <div *ngFor="let batchLineSelection of batchLineSelections; let i = index" class="mwg-chart-multi-wrapper">
                                <div *ngIf="batchHeaderInfos && batchHeaderInfos[batchLineSelection.batchId + '-' + batchLineSelection.line]?.kpiLine">
                                  <div class="flex-row">
                                    <div class="flex-50">
                                      <div class="flex-row">
                                        <h3 class="line-name">{{ batchHeaderInfos[batchLineSelection.batchId + '-' + batchLineSelection.line]?.batchNr }} - {{batchHeaderInfos[batchLineSelection.batchId + '-' + batchLineSelection.line]?.kpiLine | lineName}}</h3>
                                        <mat-icon *ngIf="tinterAddInfos[batchLineSelection.batchId + '-' + batchLineSelection.line]?.count !== 0"
                                                  class="tinter-addition-circle"
                                                  [matTooltip]="'dashboard.withTinterAdditions' | translate"
                                                  matTooltipPosition="above"
                                                  matTooltipClass="tinter-addition-tooltip">circle</mat-icon>
                                        <mat-icon *ngIf="batchHeaderInfos[batchLineSelection.batchId + '-' + batchLineSelection.line]?.batchComments?.length > 0"
                                                  class="batch-comment-circle"
                                                  [matTooltip]="batchCommentTooltips[batchLineSelection.batchId]"
                                                  matTooltipPosition="above"
                                                  matTooltipClass="multiline-tooltip">circle</mat-icon>
                                      </div>
                                      <div>
                                        <colimo-kpi [kpis]="batchHeaderInfos[batchLineSelection.batchId + '-' + batchLineSelection.line]?.kpiLine.kpis"></colimo-kpi>
                                      </div>
                                    </div>
                                    <div *ngIf="batchHeaderInfos[batchLineSelection.batchId + '-' + batchLineSelection.line]?.isBody" class="flex-50">
                                      <colimo-chart-vid [chartFilters]="{ batchId: batchLineSelection.batchId, lineId: batchLineSelection.line, models: chartFilters.models }"
                                                        [kpiLine]="batchHeaderInfos[batchLineSelection.batchId + '-' + batchLineSelection.line].kpiLine">
                                      </colimo-chart-vid>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                    <colimo-mwg-chart-list
                                            [showChartFilters]="showChartFilters"
                                            [showChartTable]="showChartTable"
                                            [selectedAngles]="selectedAngles"
                                            [activeComponents]="activeComponents"
                                            [modelsComponents]="modelsComponents"
                                            [batchId]="batchLineSelection.batchId"
                                            [lineId]="batchLineSelection.line"
                                            [(chartFilters)]="chartFilters"
                                            (tolerancesChanged)="tolerancesChanged($event)"
                                            (mwgChartLoading)="chartLoadingChanged($event)"
                                            (chartDataChange)="chartDataChanged($event)">
                                    </colimo-mwg-chart-list>
                                </div>
                            </div>
                    </div>
                </ng-template>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <div class="flex-row gap-10">
                        <span>
                          {{ 'dashboard.fdg' | translate | tabIlluminant: chartData:selectedTab:1 }}
                        </span>
                        <mat-icon
                                *ngIf="tinterAdditionsCountTotal > 0"
                                class="tinter-addition-circle"
                                [matTooltip]="'dashboard.withTinterAdditions' | translate"
                                matTooltipPosition="above"
                                matTooltipClass="tinter-addition-tooltip">circle</mat-icon>
                    </div>
                </ng-template>
                <ng-template matTabContent>
                    <div class="multi-chart-batch-line-selections">
                        <div *ngFor="let batchLineSelection of batchLineSelections" class="batch-line-border">
                            <div *ngIf="!chartLoading && batchHeaderInfos[batchLineSelection.batchId + '-' + batchLineSelection.line]?.kpiLine" class="multi-chart-batch-line">
                                <div class="flex-row">
                                    <div class="batch-color-indicator" [ngStyle]="{'background-color': chartMultiBatchColors[batchLineSelection.batchId + '-' + batchLineSelection.line] ? chartMultiBatchColors[batchLineSelection.batchId + '-' + batchLineSelection.line]: '' }"></div>
                                    <h3 class="line-name">{{ batchHeaderInfos[batchLineSelection.batchId + '-' + batchLineSelection.line]?.batchNr }} - {{batchHeaderInfos[batchLineSelection.batchId + '-' + batchLineSelection.line]?.kpiLine | lineName}}</h3>
                                    <mat-icon *ngIf="tinterAddInfos[batchLineSelection.batchId + '-' + batchLineSelection.line]?.count !== 0"
                                              class="tinter-addition-circle"
                                              [matTooltip]="'dashboard.withTinterAdditions' | translate"
                                              matTooltipPosition="above"
                                              matTooltipClass="tinter-addition-tooltip">circle</mat-icon>
                                    <mat-icon *ngIf="batchHeaderInfos[batchLineSelection.batchId + '-' + batchLineSelection.line]?.batchComments?.length > 0"
                                              class="batch-comment-circle"
                                              [matTooltip]="batchCommentTooltips[batchLineSelection.batchId]"
                                              matTooltipPosition="above"
                                              matTooltipClass="multiline-tooltip">circle</mat-icon>
                                </div>
                                <colimo-kpi [kpis]="batchHeaderInfos[batchLineSelection.batchId + '-' + batchLineSelection.line]?.kpiLine.kpis"></colimo-kpi>
                            </div>
                        </div>
                    </div>

                    <colimo-fdg-chart-list-component
                            [selectedAngles]="selectedAngles"
                            [activeComponents]="activeComponents"
                            [modelsComponents]="modelsComponents"
                            [(chartFilters)]="chartFilters"
                            [chartComponentColors]="chartComponentColors"
                            [chartComponentBorderColors]="chartComponentBorderColors"
                            [chartMultiBatchColors]="chartMultiBatchColors"
                            [projects]="projects"
                            (tolerancesChanged)="tolerancesChanged($event)"
                            (chartDataChange)="chartDataChanged($event)">
                    </colimo-fdg-chart-list-component>

                </ng-template>
            </mat-tab>
            <mat-tab [label]="'dashboard.fog' | translate | tabIlluminant: chartData:selectedTab:2">
                <ng-template matTabContent>
                    <div class="multi-chart-batch-line-selections">
                        <div *ngFor="let batchLineSelection of batchLineSelections" class="batch-line-border">
                            <div *ngIf="!chartLoading && batchHeaderInfos[batchLineSelection.batchId + '-' + batchLineSelection.line]?.kpiLine" class="multi-chart-batch-line">
                                <div class="flex-row">
                                    <div class="batch-color-indicator" [ngStyle]="{'background-color': chartMultiBatchColors[batchLineSelection.batchId + '-' + batchLineSelection.line] ? chartMultiBatchColors[batchLineSelection.batchId + '-' + batchLineSelection.line]: '' }"></div>
                                    <h3 class="line-name">{{ batchHeaderInfos[batchLineSelection.batchId + '-' + batchLineSelection.line]?.batchNr }} - {{batchHeaderInfos[batchLineSelection.batchId + '-' + batchLineSelection.line]?.kpiLine | lineName}}</h3>
                                    <mat-icon *ngIf="batchHeaderInfos[batchLineSelection.batchId + '-' + batchLineSelection.line]?.batchComments?.length > 0"
                                              class="batch-comment-circle"
                                              [matTooltip]="batchCommentTooltips[batchLineSelection.batchId]"
                                              matTooltipPosition="above"
                                              matTooltipClass="multiline-tooltip">circle</mat-icon>
                                </div>
                                <colimo-kpi [kpis]="batchHeaderInfos[batchLineSelection.batchId + '-' + batchLineSelection.line]?.kpiLine.kpis"></colimo-kpi>
                            </div>
                        </div>
                    </div>

                    <colimo-fog-chart-list-component
                        [activeComponents]="activeComponents"
                        [selectedAngles]="selectedAngles"
                        [modelsComponents]="modelsComponents"
                        [(chartFilters)]="chartFilters"
                        (tolerancesChanged)="tolerancesChanged($event)"
                        (chartDataChange)="chartDataChanged($event)"
                        [chartComponentColors]="chartComponentColors"
                        [chartMultiBatchColors]="chartMultiBatchColors">
                    </colimo-fog-chart-list-component>

                </ng-template>
            </mat-tab>
        </mat-tab-group>
    </mat-card>

</div>


