import { Injectable } from '@angular/core';
import { ModelsComponents } from '../../models/models-components.model';

/**
 * Service responsible for dealing with selected models and components.
 */
@Injectable({
  providedIn: 'root',
})
export class ModelsComponentsFactoryService {
  constructor() {}

  /**
   * Checks if body/bumper components are available and set them to null if not.
   * Without this update there is no chance to determine wether the components
   * are all deselected or if they aren't available at all.
   * @returns the updated object
   */
  public checkAvailabilityOfComponentsAndUpdate(modelsComponents: ModelsComponents): ModelsComponents {
    // Value of modelsComponents is `{}` in some cases. Therefore this check is needed here.
    if (this.isInstanceOfModelsComponents(modelsComponents)) {
      if (modelsComponents.bodyComponents.length === 0) {
        modelsComponents.bodyComponents = null;
      }
      if (modelsComponents.bumperComponents.length === 0) {
        modelsComponents.bumperComponents = null;
      }
    }
    return modelsComponents;
  }

  /**
   * Creates selected models array of the same length as input models array.
   */
  public createSelectedModelsArrayFromInput(
    inputModels: string[],
    modelsSelected: boolean[],
    preselectedModelId: string,
  ): boolean[] {
    if (inputModels && inputModels.length) {
      if (inputModels.includes(preselectedModelId)) {
        inputModels.forEach((inputModel: string, i: number) => {
          modelsSelected[i] = false;
          if (inputModels[i] === preselectedModelId) {
            modelsSelected[i] = true;
          }
        });
      } else {
        inputModels.forEach((inputModel: string, i: number) => {
          // Preserve old value if it already existed
          if (modelsSelected[i] === undefined) {
            modelsSelected[i] = true;
          }
        });
      }
    }
    return modelsSelected;
  }

  /**
   * Creates a list of selected components out of the available components
   */
  public updateSelectedComponentsArray(availableComponents: string[], actualComponents: string[]): boolean[] {
    const selectedComponents = [];
    if (availableComponents && availableComponents.length !== 0 && actualComponents) {
      availableComponents.forEach((component: string, index: number) => {
        selectedComponents[index] = actualComponents.includes(component);
      });
    }
    return selectedComponents;
  }

  /**
   * Creates selected components array of the same length as input components array.
   */
  public createSelectedComponentsArrayFromInput(inputComponents: string[], componentsSelected: boolean[]): boolean[] {
    if (inputComponents && inputComponents.length) {
      for (let i = 0; i < inputComponents.length; i++) {
        // Preserve old value if it already existed
        if (componentsSelected[i] === undefined) {
          componentsSelected[i] = true;
        }
      }
    }
    return componentsSelected;
  }

  /**
   * Provides selected models in a list as a string.
   * Returns * if all models are selected.
   */
  public getSelectedModelsAsList(modelsSelected: boolean[], models: string[]): string {
    if (modelsSelected == null || !modelsSelected.length || models == null || !models.length) {
      return '';
    }
    if (modelsSelected.filter((c) => c === false).length === 0) {
      return '*';
    }
    const modelsData = new Array<string>();
    for (let i = 0; i < modelsSelected.length; i++) {
      if (modelsSelected[i] === true) {
        modelsData.push(models[i]);
      }
    }
    return modelsData.toString();
  }

  /**
   * Provides selected components in a list as a string.
   * Returns * if all components are selected.
   */
  public getSelectedComponentsAsList(selectedComponents: boolean[], components: string[]): string {
    if (components == null) {
      return null;
    }
    if (selectedComponents == null || !selectedComponents.length || !components.length) {
      return '';
    }
    if (selectedComponents.filter((c) => c === false).length === 0) {
      return '*';
    }
    const componentsData = new Array<string>();
    for (let i = 0; i < selectedComponents.length; i++) {
      if (selectedComponents[i] === true) {
        componentsData.push(components[i]);
      }
    }
    return componentsData.toString();
  }

  /**
   * returns only contact points components if contactPoints is true else only not contact points components
   * @param availableComponents
   * @param contactPoints
   */
  public getContactPointsComponents(availableComponents: string[], contactPoints: boolean, kundNr: number): string[] {
    const selectedComponents = [];
    if (availableComponents && availableComponents.length !== 0) {
      availableComponents.forEach((component: string, index: number) => {
        if (contactPoints && this.isContactPoint(component, kundNr)) {
          selectedComponents.push(component);
        } else if (!contactPoints && !this.isContactPoint(component, kundNr)) {
          selectedComponents.push(component);
        }
      });
    }
    return selectedComponents;
  }

  private isContactPoint(component: string, kundNr: number): boolean {
  return component.startsWith('FENDER') || component.startsWith('UP') || component.startsWith('BUMPER') || (kundNr === 25 && component.startsWith('HOOD'));
  }

  /**
   * Checks if type of given object is ModelsComponents.
   * Or in other words: if all attributes are present in the object.
   */
  private isInstanceOfModelsComponents(object: ModelsComponents): object is ModelsComponents {
    return 'models' in object && 'bodyComponents' in object && 'bumperComponents' in object;
  }
}
